import React, { useState, useEffect } from "react";
import * as pdfjs from "pdfjs-dist/webpack";
import "pdfjs-dist/web/pdf_viewer.css"; // Import styles if needed
import { fetchScheduleData } from "./utils/function";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Open = () => {
  const [scheduleData, setScheduleData] = useState(null);
  const [extractedText, setExtractedText] = useState("");

  useEffect(() => {
    if (extractedText) {
      fetchScheduleData(extractedText)
        .then((res) => {
          // console.log(res);
          setScheduleData(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [extractedText]);

  const extractTextFromPdf = async (file) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const pdfUrl = event.target.result;
      const loadingTask = pdfjs.getDocument(pdfUrl);
      try {
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;
        let fullText = "";

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items
            .map((item) => item.str + "\n")
            .join(""); // Add line breaks
          fullText += pageText;
        }
        setExtractedText(fullText);
      } catch (error) {
        console.error("Error extracting text:", error);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      extractTextFromPdf(file);
    }
  };

  if (!scheduleData) {
    return (
      <div>
        <h2>Upload a PDF file to generate data</h2>
        <input type="file" accept=".pdf" onChange={handleFileChange} />
        <h2>Extracted Text={extractedText.length}</h2>
        <div className="flex">
          <pre>{extractedText}</pre>
        </div>
      </div>
    );
  }

  console.log(scheduleData);

  return (
    <div>
      <h1>Schedule for {scheduleData?.customer}</h1>
      <h2>MRN: {scheduleData?.MRN}</h2>
      <ul>
        {scheduleData?.data.map((day, index) => (
          <li key={index}>
            <p>
              Date: {day.month} {day.date}
            </p>
            {day.shift_1 && (
              <p>
                Shift 1: {day.shift_1.time}, Role: {day.shift_1.role}, Name:{" "}
                {day.shift_1.name}
              </p>
            )}
            {day.shift_2 && (
              <p>
                Shift 2: {day.shift_2.time}, Role: {day.shift_2.role}, Name:{" "}
                {day.shift_2.name}
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Open;
