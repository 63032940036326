import React from "react";
import dp from "../../assets/icon.jpg";

const UserJobCard = ({
  element,
  jobFinalSelectionNurseId,
  setJobFinalSelectionNurseId,
}) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() =>
        (!element?.id?.availableDate || !element?.id?.availableTime)
          ? alert("Nurse is not available")
          : jobFinalSelectionNurseId === element?.id?.id
            ? setJobFinalSelectionNurseId("")
            : setJobFinalSelectionNurseId(element?.id)
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          backgroundColor:
            (!element?.id?.availableDate || !element?.id?.availableTime)
              ? "#ffcccc"
              : jobFinalSelectionNurseId === element?.id?.id
                ? "#e6ffe6"
                : "#fff",
        }}
      >
        <div className="h-12 w-12 rounded-full">
          {element?.id?.profile ? (
            <img
              className="h-12 w-12 rounded-full"
              src={element?.id?.profile}
              alt=""
            />
          ) : (
            <img
              className="h-12 w-12 rounded-full"
              src={dp}
              alt=""
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <div
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 12 }}>{element?.id?.name}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 3
              }}
            >
              <p style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 10 }}>
                {element?.id?.license}
              </p>
              {<p style={{
                color: element?.totalHours < 40 ? "green" : "red",
                fontWeight: "600", fontSize: 10, marginLeft: 5
              }}>
                - {element?.totalHours}h
              </p>}
            </div>

          </div>
          <div
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: 12,
                color:
                  (!element?.id?.availableDate || !element?.id?.availableTime)
                    ? "red"
                    : jobFinalSelectionNurseId === element?.id?.id
                      ? "green"
                      : "green",
                fontWeight:
                  jobFinalSelectionNurseId === element?.id?.id ? "700" : "600",
              }}
            >
              {/* {jobFinalSelectionNurseId === element?.id?.id
                ? "Selected"
                : element?.id?.availableTime
                  ? "Available"
                  : "Not Available"} */}

              {(!element?.id?.availableDate || !element?.id?.availableTime)
                ? "Not Available"
                : jobFinalSelectionNurseId === element?.id?.id
                  ? "Selected"
                  : "Available"}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "lightgray",
          marginHorizontal: 5,
        }}
      />
    </div>
  );
};

export default UserJobCard;
