// import { Switch } from '@headlessui/react'
import React from 'react'
import List from './List'
import TabSelect from './TabSelect'
import AutoComplete from './AutoComplete'
import { DatePicker1Presentation } from './DatePicker'
import { MdOutlineAdd } from 'react-icons/md'
import MultiSelect from './MultiSelect'
import AutoCompleteManager from './AutoCompleteManager'
import { Checkbox1Presentation } from '../user/Switch'

function JobForm({
  jobNewData,
  licenseTypeArr,
  setLicenseTypeArr,
  handleInputChangeNewData,
  customerList,
  managerList,
  type,
  edit,
  orgLocationIdValue
}) {

  const optionsBreak = [
    { name: "No Break" },
    { name: "15 Minutes" },
    { name: "30 Minutes" },
    { name: "45 Minutes" },
    { name: "1 Hour" },
  ]

  return (
    <div className='pb-20'>
      <div className="grid gap-6 grid-cols-2 border-b-2 mb-5
       items-center center">
        <div>
          <div className="flex items-center">
            <div className="flex h-12 w-12 flex-shrink-0
             items-center justify-center rounded-full bg-green-100 ">
              <MdOutlineAdd className="text-green-600 " size={24} />
            </div>
            <p className="text-xl font-medium leading-6 text-gray-900 ml-3"
            >
              {edit ? "Update Schedule" : "Create New Schedule"}
            </p>
          </div>
        </div>
        <div className='mb-3'>
          <label for="shiftTitle"
            className="block mb-2 font-medium text-blue-500">Job Type - {orgLocationIdValue}</label>
          <TabSelect jobNewData={jobNewData} handleInputChangeNewData={handleInputChangeNewData} type={type} />
        </div>
      </div>
      <div className="grid gap-6 grid-cols-3">
        <div>
          <div className='flex'>
            <label
              for="manager"
              className="block mb-2 text-sm font-medium text-gray-900">
              Manager </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <AutoCompleteManager
              jobNewData={jobNewData}
              handleInputChangeNewData={handleInputChangeNewData}
              option={managerList}
            />
          </div>
        </div>
        <div>
          <div className='flex'>
            <label for="shiftTitle"
              className="block mb-2 text-sm font-medium text-gray-900">{type} Title
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="shiftTitle"
            name='shiftTitle'
            value={jobNewData?.shiftTitle}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Title"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label
              for="startDate"
              className="block mb-2 text-sm font-medium text-gray-900">
              License
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <MultiSelect selectedValue={licenseTypeArr} setSelectedValue={setLicenseTypeArr} />
        </div>
        <div>
          <label
            for="startDate"
            className="block mb-2 text-sm font-medium text-gray-900">
            Start Date
          </label>
          <DatePicker1Presentation
            date={jobNewData?.startDate}
            handleInputChange={handleInputChangeNewData}
            field={"startDate"}
            type={type} />
        </div>
        <div>
          <label
            for="endDate"
            className="block mb-2 text-sm font-medium text-gray-900">
            End Date
          </label>
          <DatePicker1Presentation
            date={jobNewData?.endDate}
            handleInputChange={handleInputChangeNewData}
            field={"endDate"}
            type={type} />
        </div>
        <div>
          <div className='flex'>
            <label
              for="customerVisibility"
              className="block mb-2 text-sm font-medium text-gray-900">
              Break </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <List
              data={jobNewData}
              handleInputChangeNewData={handleInputChangeNewData}
              options={optionsBreak}
              edit={edit?.break} />
          </div>
        </div>
        <div>
          <div className='flex'>
            <label
              for="customer"
              className="block mb-2 text-sm font-medium text-gray-900">
              Patients </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <AutoComplete
              state={jobNewData}
              handleInputChangeNewData={handleInputChangeNewData}
              option={customerList}
            />
          </div>
        </div>
        <div className='flex'>
          <div>
            <Checkbox1Presentation
              name={"After Assignment"}
              label={"After Assignment"}
              checked={jobNewData?.customerVisibility}
              onChange={(event) => handleInputChangeNewData(
                {
                  "target": {
                    "name": "customerVisibility",
                    "value": event
                  }
                })} />
          </div>
          <div>
          </div>
        </div>
        {/* <div>
          <label
            for="customerVisibility"
            className="block mb-2 text-sm font-medium text-gray-900">
            After Assignment</label>
          <Switch
            checked={jobNewData?.customerVisibility}
            onChange={(event) => handleInputChangeNewData(
              {
                "target": {
                  "name": "customerVisibility",
                  "value": event
                }
              })}
            className={`${jobNewData?.customerVisibility ? 'bg-green-600' : 'bg-green-900'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
          border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${jobNewData?.customerVisibility ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
             bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div> */}
        <div>
          <div className='flex'>
            <label
              for="fullAddress"
              className="block mb-2 text-sm font-medium text-gray-900">
              Full Address</label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            name='fullAddress'
            id="fullAddress"
            value={jobNewData?.fullAddress}
            className="bg-gray-50 
          border border-gray-300 text-gray-900 text-sm rounded-lg
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
              
             "
            placeholder="Enter Full Address"

            disabled
            onChange={e => handleInputChangeNewData(e)} />
        </div>
        <div>
          <label
            for="notes"
            className="block mb-2 text-sm font-medium text-gray-900">Notes</label>
          <input
            type="text"
            name='notes'
            id="notes"
            value={jobNewData?.notes}
            className="bg-gray-50 
          border border-gray-300 text-gray-900 text-sm rounded-lg
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
              
             "
            placeholder="Enter Notes"

            onChange={e => handleInputChangeNewData(e)} />
        </div>
      </div>
      {/* <div className="flex items-start mb-6">
        <div className="flex items-center h-5">
          <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300   dark:focus:ring-blue-600 dark:ring-offset-gray-800"   />
        </div>
        <label for="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
      </div> */}
      {/* <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button> */}
    </div >
  )
}

export default JobForm