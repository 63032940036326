import { API_URL } from "./config";

export async function getCustomerDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export async function getCustomerList(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/location/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export async function getCustomerTableList(authToken, location, organization) {
  if (!authToken) {
    return;
  }

  // console.log(`${API_URL}/customer/organization/location/${location}/${organization}`)

  const res = await fetch(`${API_URL}/customer/organization/location/${location}/${organization}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching template.");
  }
  return await res.json();
}

export const deleteCustomerMulti = async (authToken, ids) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/multi/delete`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ids: ids,
    }),
  });

  //console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    //console.log("Non-JSON response:", textResult);
    return textResult;  // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      //console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};

export const deleteCustomer = async (authToken, id) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json",
    },
  });

  //console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    //console.log("Non-JSON response:", textResult);
    return textResult;  // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      //console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};

export async function updateDetails(authToken, id, data) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}

export async function createCustomer(authToken, data) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}

export async function getCustomerId(authToken, name) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/customer/customer/name/${name}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    }
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
}