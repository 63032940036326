import React, { useState } from 'react'
import JobTableView from './JobTableView'

function JobView() {

  const [tab, setTab] = useState("Shift")

  return (
    <div>
      <div className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        <div
          className={`
      w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
      ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
      ${tab === "Shift"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
      `}
          onClick={() => { setTab("Shift") }}
        >
          Shift Jobs
        </div>
        <div
          className={`
      w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
      ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
      ${tab === "Visit"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
      `}
          onClick={() => { setTab("Visit") }}
        >
          Visit Jobs
        </div>
      </div>
      {
        <JobTableView type={tab} />
      }
    </div>
  )
}

export default JobView