// import { Switch } from '@headlessui/react'
import { Checkbox1Presentation } from './Switch'
import React from 'react'
import { MdOutlineAdd } from 'react-icons/md'

function FacilityForm({
  jobNewData,
  handleInputChangeNewData,
  edit,
  orgLocationIdValue
}) {

  const format = (value) => {
    const inputPhoneNumber = value.replace(/\D/g, '');
    const limitedPhoneNumber = inputPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = limitedPhoneNumber.length === 10 ? limitedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : limitedPhoneNumber;
    return formattedPhoneNumber
  }

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value
    handleInputChangeNewData({
      target: {
        name: "phoneNumber",
        value: inputPhoneNumber
      },
    })
  };

  return (
    <div className='pb-20'>
      <div className="grid gap-6 grid-cols-2 border-b-2 mb-5
       items-center center">
        <div>
          <div className="flex items-center">
            <div className="flex h-12 w-12 flex-shrink-0
             items-center justify-center rounded-full bg-green-100 ">
              <MdOutlineAdd className="text-green-600 " size={24} />
            </div>
            <p className="text-xl font-medium leading-6 text-gray-900 ml-3"
            >
              {edit ? "Update User" : "Create New User"}
            </p>
          </div>
        </div>
        <div className='items-end flex justify-end font-medium text-blue-500'>
          {orgLocationIdValue}
        </div>
      </div>
      <div className="grid gap-6 grid-cols-3">
        <div>
          <div className='flex'>
            <label for="firstName"
              className="block mb-2 text-sm font-medium text-gray-900">First Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="firstName"
            name='firstName'
            value={jobNewData?.firstName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 "
            placeholder="Enter First Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="lastName"
              className="block mb-2 text-sm font-medium text-gray-900">Last Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="lastName"
            name='lastName'
            value={jobNewData?.lastName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Last Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="email"
              className="block mb-2 text-sm font-medium text-gray-900">Email
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="email"
            name='email'
            value={jobNewData?.email}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Email"
            onChange={e => handleInputChangeNewData(e)}
          />
          {jobNewData?.email && !jobNewData?.email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
          )}
        </div>
        <div>
          <div className='flex'>
            <label for="phoneNumber"
              className="block mb-2 text-sm font-medium text-gray-900">Phone Number
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="phoneNumber"
            name='phoneNumber'
            value={format(jobNewData?.phoneNumber)}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Phone Number"
            onChange={e => handlePhoneNumberChange(e)}
            pattern="[0-9]*"
          />
        </div>
        <div>
          <div className='flex'>
            <label for="password"
              className="block mb-2 text-sm font-medium text-gray-900">Password
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="password"
            name='password'
            value={jobNewData?.password}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Password"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        {edit !== undefined ?
          <div className='flex space-x-5'>
            {/* <div>
              <label
                for="facilityLoginControl"
                className="block mb-2 text-sm font-medium text-gray-900">
                DeActivate</label>
              <Switch
                checked={jobNewData?.facilityLoginControl}
                onChange={(event) => handleInputChangeNewData(
                  {
                    "target": {
                      "name": "facilityLoginControl",
                      "value": event
                    }
                  })}
                className={`${jobNewData?.facilityLoginControl ? 'bg-green-600' : 'bg-green-900'}
     relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
     border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
       focus-visible:ring-white/75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${jobNewData?.facilityLoginControl ? 'translate-x-9' : 'translate-x-0'}
       pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
        bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div> */}
            <div>
              <Checkbox1Presentation
                name={"DeActivate"}
                label={"DeActivate"}
                checked={!jobNewData?.facilityLoginControl}
                onChange={(event) => handleInputChangeNewData(
                  {
                    "target": {
                      "name": "facilityLoginControl",
                      "value": !event
                    }
                  })} />
            </div>
            {/* {jobNewData?.facilityLoginControl && <div>
              <Checkbox1Presentation
                name={"Suspend"}
                label={"Suspend"}
                checked={jobNewData?.facilityAppAccessControl}
                onChange={(event) => handleInputChangeNewData(
                  {
                    "target": {
                      "name": "facilityAppAccessControl",
                      "value": event
                    }
                  })} />
            </div>} */}
            {/* <div>
              <label
                for="facilityAppAccessControl"
                className="block mb-2 text-sm font-medium text-gray-900">
                Suspend</label>
              <Switch
                checked={jobNewData?.facilityAppAccessControl}
                onChange={(event) => handleInputChangeNewData(
                  {
                    "target": {
                      "name": "facilityAppAccessControl",
                      "value": event
                    }
                  })}
                className={`${jobNewData?.facilityAppAccessControl ? 'bg-green-600' : 'bg-green-900'}
     relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
     border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
       focus-visible:ring-white/75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${jobNewData?.facilityAppAccessControl ? 'translate-x-9' : 'translate-x-0'}
       pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
        bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div> */}
          </div>
          :
          // <div>
          //   <label
          //     for="emailVerified"
          //     className="block mb-2 text-sm font-medium text-gray-900">
          //     OTP Verified</label>
          //   <Switch
          //     checked={jobNewData?.emailVerified}
          //     onChange={(event) => handleInputChangeNewData(
          //       {
          //         "target": {
          //           "name": "emailVerified",
          //           "value": event
          //         }
          //       })}
          //     className={`${jobNewData?.emailVerified ? 'bg-green-600' : 'bg-green-900'}
          // relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
          // border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
          //   focus-visible:ring-white/75`}
          //   >
          //     <span className="sr-only">Use setting</span>
          //     <span
          //       aria-hidden="true"
          //       className={`${jobNewData?.emailVerified ? 'translate-x-9' : 'translate-x-0'}
          //   pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
          //    bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          //     />
          //   </Switch>
          // </div>
          <div className='flex'>
            <div>
              <Checkbox1Presentation
                name={"OTP Verification"}
                label={"OTP Verification"}
                checked={!jobNewData?.emailVerified}
                onChange={(event) => handleInputChangeNewData(
                  {
                    "target": {
                      "name": "emailVerified",
                      "value": !event
                    }
                  })} />
            </div>
            <div>
            </div>
          </div>
        }
      </div>
      {/* <div className="flex items-start mb-6">
        <div className="flex items-center h-5">
          <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300   dark:focus:ring-blue-600 dark:ring-offset-gray-800"   />
        </div>
        <label for="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
      </div> */}
      {/* <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button> */}
    </div >
  )
}

export default FacilityForm