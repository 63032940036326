import React, { useState } from "react";

function SideTab() {
  const [tab, setTab] = useState("about");
  return (
    <div class="site-wrapper">
      <section class="tabs-wrapper">
        <div class="tabs-container">
          <div class="tabs-block">
            <div id="tabs-section" class="tabs">
              <ul class="tab-head">
                <li>
                  <a
                    onClick={() => setTab("about")}
                    className={`tab-link cursor-pointer ${
                      tab === "about" ? "active" : ""
                    }`}
                  >
                    {/* <span class="material-icons tab-icon">face</span> */}
                    <span class="tab-label">About</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setTab("how")}
                    className={`tab-link cursor-pointer ${
                      tab === "how" ? "active" : ""
                    }`}
                  >
                    {/* <span class="material-icons tab-icon">visibility</span> */}
                    <span class="tab-label">How it works</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setTab("privacy")}
                    className={`tab-link cursor-pointer ${
                      tab === "privacy" ? "active" : ""
                    }`}
                  >
                    {/* <span class="material-icons tab-icon">
                      settings_input_hdmi
                    </span> */}
                    <span class="tab-label">Privacy Policy</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setTab("terms")}
                    className={`tab-link cursor-pointer ${
                      tab === "terms" ? "active" : ""
                    }`}
                  >
                    {/* <span class="material-icons tab-icon">build</span> */}
                    <span class="tab-label">Terms of use</span>
                  </a>
                </li>
              </ul>

              <section
                id="tab-1"
                class={`tab-body entry-content ${
                  tab === "about" ? "active active-content" : ""
                }`}
              >
                <span>
                  <h1 className="txtBlue"> Welcome to NURSD Health</h1>
                  <p>
                    NURSD Health is a B2B SaaS platform providing Healthcare
                    facilities with a smart and intelligent workforce management
                    system.
                  </p>
                  <p>
                    Our unparalleled matching technology understands the needs
                    of healthcare facilities and nursing professionals at a
                    hyper-local level to improve care by automatically
                    optimizing and augmenting staff.
                  </p>
                  <p>
                    We give nurses and CNAs the freedom and flexibility to pick
                    up shifts right from our mobile app.
                  </p>
                  <p>
                    We aim to solve the last-minute staffing problem and improve
                    Care quality and patient safety, We built the NURSD Health
                    Platform to return caregiving to it's roots.
                  </p>
                </span>
              </section>

              <section
                id="tab-2"
                class={`tab-body entry-content ${
                  tab === "how" ? "active active-content" : ""
                }`}
              >
                <span>
                  <h1>How it works</h1>
                  <p>
                    Here you can find information about how NURSD Health works.
                  </p>
                  <h3 className="txtBlue">For Healthcare Facilities </h3>
                  <p>
                    Post your requirement. Tell us your need. It's FREE to post
                  </p>
                  <p>
                    Healthcare talent 24/7. Get offers from trusted nurses and
                    view profiles
                  </p>
                  <p>
                    Submit and leave it to us. Choose the right person for your
                    requirement and get the job done
                  </p>
                  <h3 className="txtBlue">How to Join as a Nurse</h3>
                  <p> Register, Login or signup as a freelancer</p>
                  <p>
                    Find a Job. Search for available shifts close to your home.
                  </p>
                  <p>
                    Apply and leave it to us. Do what you love most "taking care
                    of patients" and start earning
                  </p>
                </span>
              </section>

              <section
                id="tab-3"
                class={`tab-body entry-content ${
                  tab === "privacy" ? "active active-content" : ""
                }`}
              >
                <span>
                  <h2 className="txtBoldLite">Nursd Privacy Policy</h2>
                  <p className="txtBold">Effective Date: August 8, 2021</p>
                  <p>
                    Thank you for visiting wmw.nursdinc.com (the "Site") or
                    www.nursdhealth.com (the "Site") or www.nursd.app (the
                    "Site"), owned and operated by NURSD LLC. ("NURSD LLC.,"
                    "we," or "our"). We are committed to treating the personal
                    and corporate information Of our users ("user," "users,"
                    "you," or "your") with respect and sensitivity. This privacy
                    policy (the "Privacy Policy") is part of our Terms and
                    Conditions of Use of NURSD LLC. Site and Services (the
                    "Terms"), Please click here to see the Terms.
                  </p>
                  <h3 className="txtBlue">
                    Application of this Privacy Policy
                  </h3>
                  <p>
                    This Privacy Policy describes how we treat information that
                    we collect both through our Site as well as through any
                    associated mobile application.
                  </p>
                  <h3 className="txtBlue">Changes to our Privacy Policy</h3>
                  <p>
                    As our services evolve and we perceive the need or
                    desirability of using information collected in other ways,
                    we may from time to time amend this Privacy Policy. We
                    encourage you to check our Site frequently to see the
                    current Privacy Policy and Terms in effect and any changes
                    that may have been made to them. If we make material changes
                    to this Privacy Policy, we will post the revised Privacy
                    Policy and the revised effective date on this Site.
                  </p>
                  <h3 className="txtBlue">Information You Provide Us</h3>
                  <p>
                    In general, you can visit the Site without telling us who
                    you are or revealing any information about yourself.
                    However, if you wish to contact us through the Contact Us
                    link in our site footer or by emailing us at
                    support@nursdhealth.com, we may collect your name, company
                    name. email address, tele-phone number and street address
                    along with other information you may choose to submit, as
                    part of the inquiry process.
                  </p>
                  <p>
                    When you register for a NURSD LLC, account as a Client (as
                    defined in the Terms), we collect information from you that
                    may include your name, company name, email address,
                    telephone number, street address, taxpayer and immigration
                    information, payroll, insurance and banking information as
                    well as other information that you submit as part of your
                    profile or job posting. We may also collect information
                    about you from other publicly-avail- able Sources. which We
                    may combine with the Other information we have.
                  </p>
                  <p>
                    When you register for a NURSD LLC. account as a Nurse (as
                    defined in the Terms), we collect information from you that
                    may include your name, email address, telephone number,
                    street address, taxpayer and immigration information.
                    education and work history and credentialing information as
                    well as other information that you submit as part of your
                    profile or job posting. We may also collect information
                    about you from other publicly available sources, which we
                    may combine with the other information we have.
                  </p>
                  <p>
                    You may be asked to choose a user name, such as your email
                    address, and a password that will be used to access your
                    account. By providing your information to us, you explicitly
                    agree to our collection and use of such information as
                    described in this Privacy Policy.
                  </p>
                  <p>
                    The information you provide us must be accurate and up to
                    date. We may verify or validate the information that you
                    provide us th rough third parties.
                  </p>
                </span>
              </section>

              <section
                id="tab-4"
                class={`tab-body entry-content ${
                  tab === "terms" ? "active active-content" : ""
                }`}
              >
                <span>
                  <h2 className="txtBoldLite">
                    Terms and Conditions of Use of NURSD Site and Services
                  </h2>
                  <p className="txtBold">
                    NURSD Terms of Service Agreement for Nurses and Allied
                    Professionals
                  </p>
                  <p> Last Revised:[18-8-21]</p>
                  <p>
                    Please read this Terms of Service Agreement
                    <span className="txtBold">("TOS Agreement")</span>
                    carefully. This TOS Agreement between NURSD, LLC., an
                    Illinois company,
                    <span className="txtBold">
                      ("NURSD" "we" "us", or "our")
                    </span>
                    and the user ("you" or "User") govern the use of: (i) our
                    websites, https://nursdhealth.com/and https:nursd.app and
                    https://nursdinc.com (the
                    <span className="txtBold"> "Website"</span>); (ii) the
                    services and resources available or enabled via our Website
                    (the
                    <span className="txtBold">"Services"</span>); and (iii) all
                    content, in- cluding. designs. graphics, text,
                    illustrations, icons, multimedia, and other material that
                    you see or read, and all related code (collectively,
                    <span className="txtBold">"Our Content"</span>).
                    Collectively the Website, Services, and Our Content are
                    <span className="txtBold">"Our Properties."</span>
                  </p>
                  <p>
                    This TOS Agreement applies to all users visiting, accessing,
                    or using Our Properties. By clicking the "l accept" button.
                    completing the registration process, or browsing our
                    Website, you represent that: (1) you have read, understand,
                    and agree to be bound by this TOS Agreement, (2) you are of
                    legal age to form a bindi Contract with NURSD, and (3) you
                    have the authority to enter into the TOS Agreement
                    personally or on behalf Of the Company (e.g. a Medical
                    Facility) you named as the User, and to bind that company to
                    the TOS Agreement. For clarity, the term "you" refers to the
                    individual or le- gal entity, as applicable, identified as
                    the user when you registered on the Website.
                  </p>
                  <p>
                    IF YOU DO NOT AGREE TO BE BOUND BY THIS TOS AGREEMENT, YOU
                    MAY NOT ACCESS OR USE OUR PROPERTIES.
                  </p>
                  <p>
                    NURSD DOES NOT EMPLOY, RECOMMEND, ENDORSE, OR GUARANTEE ANY
                    PROFESSIONALS OR MEDICAL FACILITIES (AS DEFINED IN SECTION 2
                    (NURSD A VENUE)), OUR PROPERTIES ARE A VENUE FOR USERS TO
                    CONNECT ONLINE. EACH USER IS SOLELY RESPONSIBLE FOR
                    INTERACTING WITH AND SELECTING ANOTHER USER, CONDUCTING ALL
                    NECESSARY DUE DILIGENCE, AND COMPLYING WITH ALL APPLICABLE
                    LAWS.
                  </p>
                  <p>
                    THE ARBITRATION AGREEMENT IN SECTION 17 (ARBITRATION) OF
                    THIS TOS AGREEMENT CONTAINS PROVISIONS GOVERNING HOW CLAIMS
                    THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED. IN
                    PARTICULAR, THE ARBITRATION AGREEMENT WILL, WITH LIMITED
                    EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO
                    BINDING AND FINAL ARBITRATION. (1) YOU WILL ONLY BE
                    PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN
                    INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                    CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) you
                    ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND
                    TO HAVE A JURY TRIAL ON YOUR CLAIMS.
                  </p>
                  <p>
                    ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO YOUR USE OF OUR
                    PROPERTIES WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE
                    LAWS OF THE STATE OF ILLINOIS, CONSISTENT WITH THE FEDERAL
                    ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES
                    THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY OTHER
                    JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR
                    THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM
                    THIS TOS AGREEMENT.
                  </p>
                  <p>
                    Your use of, and participation in. certain Services may be
                    subject to additional terms
                    <span className="txtBold"> ("Supplemental Terms")</span>.
                    Such Supplemental Terms will either be listed in this TOS
                    Agreement or will be presented to you for your acceptance
                    when you sign up to use the supplemental Service. If the TOS
                    Agreement is inconsistent with the Supplemental Terms, the
                    Supplemental Terms shall control with respect to such
                    Service, This TOS Agreement and any applicable Supplemental
                    Terms are referred to herein as the
                    <span className="txtBold">"Agreement."</span>
                  </p>
                  <p>
                    Except as provided in section 17.8 and 19.7 below this
                    Agreement is subject to change by us in our sole discretion
                    at any time.
                  </p>
                </span>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SideTab;
