import { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userUniqueId, setUserUniqueId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("Calling fetch data")
      const storedToken = localStorage.getItem("authToken");
      const storeUserId = localStorage.getItem("userId");
      const storeUserUniqueId = localStorage.getItem("userUniqueId");
      if (storedToken) {
        setAuthToken(storedToken);
        setUserId(storeUserId);
        setUserUniqueId(storeUserUniqueId);
      }
    }
    fetchData()
  }, []);

  const updateAuthToken = async (newToken, userId, userUniqueId) => {
    // console.log("updateAuthToken", newToken, userId, userUniqueId) 
    localStorage.setItem("authToken", newToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userUniqueId", userUniqueId);
    setAuthToken(newToken);
    setUserId(userId);
    setUserUniqueId(userUniqueId);
  };

  const removeAuthToken = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userUniqueId");
    setAuthToken(null);
    setUserId(null);
    setUserUniqueId(null);
  };

  return (
    <AuthContext.Provider
      value={{ authToken, userId, userUniqueId, updateAuthToken, removeAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
