import React, { Fragment, useEffect, useState } from "react";
import { MdEdit, MdOutlineClose, MdDelete } from "react-icons/md";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import NurseForm from "./NurseForm";
import { useAuth } from "../../context/AuthContext";
import { getAllFacilityOrgList, getAllNurseOrgList, getOrganizationDetails } from "../../api_url/organizationTable";
import { createNurse, updateDetails } from "../../api_url/nurseTable";
import List from "../../components/ListLocation";
import { sendNotification } from "../../api_url/webNotification";
import { postFacilityNotification } from "../../api_url/facilityNotification";
import { socket } from "../../api_url/socket";

function NurseTableView() {

  const history = useHistory();
  const { authToken, userId } = useAuth();

  const [open, setOpen] = useState({
    condition: false,
    item: {},
  });

  const [deleteAll, setDeleteAll] = useState(false);
  const [addRecord, setAddRecord] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orgLocation, setOrgLocation] = useState("");
  const [orgLocationId, setOrgLocationId] = useState([]);
  const [orgLocationIdAddress, setOrgLocationIdAddress] = useState([]);
  const [orgLocationIdView, setOrgLocationIdView] = useState(false);
  const [orgLocationIdValue, setOrgLocationIdValue] = useState("");
  const [orgLocationIdValueList, setOrgLocationIdValueList] = useState(
    {
      "name": "Select location Id"
    }
  );

  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [edit, setEdit] = useState(undefined);
  const [editedData, setEditedData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [address, setAddress] = useState(false);
  const [managerList, setManagerList] = useState(undefined);

  const format = (value) => {
    const inputPhoneNumber = value.replace(/\D/g, '');
    const limitedPhoneNumber = inputPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = limitedPhoneNumber.length === 10 ? limitedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : limitedPhoneNumber;
    return formattedPhoneNumber
  }

  const [jobColumnsData, setJobColumnsData] = useState([
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "firstName",
      accessor: "firstName",
    },
    {
      Header: "lastName",
      accessor: "lastName",
    },
    {
      Header: "emailId",
      accessor: "emailId",
    },
    {
      Header: "password",
      accessor: "password",
    },
    {
      Header: "phoneNumber",
      accessor: "phoneNumber",
    },
    {
      Header: "gender",
      accessor: "gender",
    },
    {
      Header: "mobileId",
      accessor: "mobileId",
    },
    {
      Header: "dateOfBirth",
      accessor: "dateOfBirth",
    },
    {
      Header: "primaryState",
      accessor: "primaryState",
    },
    {
      Header: "primaryLicenseType",
      accessor: "primaryLicenseType",
    },
    {
      Header: "primaryLicenseMultiPrivilege",
      accessor: "primaryLicenseMultiPrivilege",
    },
    {
      Header: "clinicalLicenseNumber",
      accessor: "clinicalLicenseNumber",
    },
    {
      Header: "clinicalLicenseExpirationDate",
      accessor: "clinicalLicenseExpirationDate",
    },
    {
      Header: "registeredWithAn",
      accessor: "registeredWithAn",
    },
    {
      Header: "employeeId",
      accessor: "employeeId",
    },
    {
      Header: "employeeName",
      accessor: "employeeName",
    },
    {
      Header: "fullAddress",
      accessor: "fullAddress",
    },
    {
      Header: "nurseVerified",
      accessor: "nurseVerified",
    },
    {
      Header: "emailVerified",
      accessor: "emailVerified",
    },
    {
      Header: "nurseLoginControl",
      accessor: "nurseLoginControl",
    },
    {
      Header: "nurseAppAccessControl",
      accessor: "nurseAppAccessControl",
    }
  ]);

  // Get the current date
  const currentDate = new Date();

  // Subtract 18 years from the current date
  const date18YearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

  // Display the date 18 years ago
  // console.log(date18YearsAgo); 

  const [jobNewData, setJobNewData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: date18YearsAgo,
    primaryState: "",
    fullAddress: "",
    latitude: "",
    longitude: "",
    organization: "",
    location_id: "",
    primaryLicenseType: "",
    primaryLicenseMultiPrivilege: false,
    clinicalLicenseNumber: "",
    clinicalLicenseExpirationDate: new Date(),
    nurseVerified: false,
    emailVerified: false,
    nurseLoginControl: false,
    nurseAppAccessControl: false,
  });

  const getOrgLocationList = async () => {
    await getOrganizationDetails(authToken, userId)
      .then((res) => {
        let arr = [];
        let arrState = [];
        // console.log(res)
        if (res) {
          setOrgLocation(res.organizationName);
          res?.locations.map((i) => arr.push({
            name: i.location_id
          }));
          if (res?.locations.length === arr.length) {
            // console.log(arr);
            setOrgLocationId(arr);
          }

          res?.locations.map((i) => arrState.push({
            "location": i.location_id,
            "state": i.location_state
          }));
          if (res?.locations.length === arrState.length) {
            // console.log(arrState);
            setOrgLocationIdAddress(arrState);
          }
          setLoadingScreen(false);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };

  const getLocationJobList = async (location, organization) => {
    setLoading(true);
    setEditedData([]);
    setDownloadData([]);
    try {
      const managerData = await getAllFacilityOrgList(authToken, location, organization);

      const nurseData = await getAllNurseOrgList(authToken, location, organization);

      let array = [];

      for (let i = 0; i < nurseData?.length; i++) {
        const item = nurseData[i];
        let obj = {
          // id: item.id,
          // uniqueId: item.uniqueId,
          // createdAt: item.createdAt,
          // updatedAt: item.updatedAt,
          emailId: item.emailId,
          firstName: item.firstName,
          lastName: item.lastName,
          password: item.password,
          phoneNumber: item.phoneNumber,
          gender: item.gender,
          // profileImage: item.profileImage,
          // mobileId: item.mobileId,
          dateOfBirth: moment(item.dateOfBirth).format("MM-DD-YYYY"),
          // primaryState: item.primaryState,
          primaryLicenseType: item.primaryLicenseType,
          primaryLicenseMultiPrivilege: item.primaryLicenseMultiPrivilege ? "Yes" : "No",
          clinicalLicenseNumber: item.clinicalLicenseNumber,
          clinicalLicenseExpirationDate: moment(item.clinicalLicenseExpirationDate).format("MM-DD-YYYY"),
          // registeredWithAn: item.registeredWithAn,
          // employeeId: item.employeeId,
          // employeeName: item.employeeName,
          fullAddress: item.fullAddress,
          // latitude: item.latitude,
          // longitude: item.longitude,
          // currentLatitude: item.currentLatitude,
          // currentLongitude: item.currentLongitude,
          // organization: item.organization,
          // location_id: item.location_id,
          // nurseVerified: item.nurseVerified ? "Yes" : "No",
          // emailVerified: item.emailVerified ? "Yes" : "No",
          // nurseLoginControl: item.nurseLoginControl ? "Yes" : "No",
          // nurseAppAccessControl: item.nurseAppAccessControl ? "Yes" : "No",
        };
        array.push(obj);
      }

      setManagerList(managerData)
      // console.log(duplicates); 
      if (nurseData?.length === array?.length) {
        setEditedData(nurseData);
        setDownloadData(array);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setManagerList(undefined)
      setEditedData([]);
      setDownloadData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrgLocationList();
  }, [authToken, userId]);

  useEffect(() => {
    if (orgLocationIdValue !== "" && orgLocation !== "") {
      getLocationJobList(orgLocationIdValue, orgLocation);
      // let state = orgLocationIdAddress?.find(i => i?.location === orgLocationIdValue)
      // setJobNewData({
      //   ...jobNewData,
      //   primaryState: state?.state
      // })
    }
  }, [orgLocationIdValue, itemsPerPage]);

  const handleInputChangeNewData = (event) => {
    setJobNewData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const validateFields = (data) => {
    let isValid = true;
    const emptyFields = [];

    const email = data?.email;
    const firstName = data?.firstName;
    const lastName = data?.lastName;
    const password = data?.password;
    const phoneNumber = data?.phoneNumber;
    const gender = data?.gender;
    const fullAddress = data?.fullAddress;
    const primaryLicenseType = data?.primaryLicenseType;

    if (
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      email === "" ||
      phoneNumber === "" ||
      gender === "" ||
      primaryLicenseType === "" ||
      fullAddress === ""
    ) {
      isValid = false;

      if (firstName === "") {
        emptyFields.push("First Name is missing");
      }
      if (lastName === "") {
        emptyFields.push("Last Name is missing");
      }
      if (password === "") {
        emptyFields.push("Password is missing");
      }
      if (fullAddress === "") {
        emptyFields.push("Address is missing");
      }
      if (email === "") {
        emptyFields.push("Email is missing");
      }
      if (phoneNumber === "") {
        emptyFields.push("Phone Number is missing");
      }
      if (primaryLicenseType === "") {
        emptyFields.push("License Type is missing");
      }
      if (gender === "") {
        emptyFields.push("gender is missing");
      }
    }

    return {
      isValid,
      emptyFields,
    };
  };

  // const dataCheck = !editedData.some((serverItem) => {
  //   return (
  //     serverItem.shiftTitle === jobNewData.shiftTitle
  //   );
  // });

  // const dataCheckEdit = !editedData.some((serverItem) => {
  //   return (
  //     serverItem.patient_first_name === edit?.patient_first_name &&
  //     serverItem.patient_last_name === edit?.patient_last_name &&
  //     serverItem.patient_address_line_1 === edit?.patient_address_line_1 &&
  //     serverItem.patient_city === edit?.patient_city &&
  //     serverItem.patient_state === edit?.patient_state &&
  //     serverItem.patient_zip_code === edit?.patient_zip_code
  //   );
  // }); 

  const postJob = async () => {
    const validationResult = validateFields(jobNewData);
    if (validationResult.isValid) {
      // if (dataCheck) {
      let state = orgLocationIdAddress?.find(i => i?.location === orgLocationIdValue)
      try {
        await createNurse(authToken, {
          email: jobNewData?.email,
          firstName: jobNewData?.firstName,
          lastName: jobNewData?.lastName,
          password: jobNewData?.password,
          phoneNumber: jobNewData?.phoneNumber,
          gender: jobNewData?.gender,
          dateOfBirth: jobNewData?.dateOfBirth,
          primaryState: state?.state,
          fullAddress: jobNewData?.fullAddress,
          latitude: jobNewData?.latitude.toString(),
          longitude: jobNewData?.longitude.toString(),
          location_id: orgLocationIdValue,
          organization: orgLocation,
          primaryLicenseType: jobNewData?.primaryLicenseType,
          primaryLicenseMultiPrivilege: jobNewData?.primaryLicenseMultiPrivilege,
          clinicalLicenseNumber: jobNewData?.clinicalLicenseNumber,
          clinicalLicenseExpirationDate: jobNewData?.clinicalLicenseExpirationDate,
          nurseVerified: jobNewData?.emailVerified,
          emailVerified: jobNewData?.emailVerified,
        }).then(res => {
          alert(
            res?.firstName + " " + res?.lastName +
            " created successfully."
          );
          managerList?.map(async (item) => {
            await postFacilityNotification(
              authToken,
              item?.id,
              "",
              "New employee joined!",
              `${res?.firstName + " " + res?.lastName} has been added as a new employee to your location.`,
              "ManageUser",
              {},
              false,
              false,
              "green",
              ""
            );
            socket?.emit("tableNotificationInsert",
              {
                table: "FacilityNotificationTable",
                id: item?.id,
              });
            if (item?.mobileId) {
              sendNotification(authToken, {
                expoPushToken: item?.mobileId,
                title: "New employee joined!",
                body: `${res?.firstName + " " + res?.lastName} has been added as a new employee to your location.`,
                data: { screen: "ManageUser" },
              })
            }
          });
          setJobNewData({
            email: "",//
            firstName: "",//
            lastName: "",//
            password: "",//
            phoneNumber: "",//
            gender: "",//
            dateOfBirth: date18YearsAgo,//
            primaryState: "",//
            fullAddress: "",
            latitude: "",
            longitude: "",
            organization: "",//
            location_id: "",//
            primaryLicenseType: "",//
            primaryLicenseMultiPrivilege: false,//
            clinicalLicenseNumber: "",//
            clinicalLicenseExpirationDate: new Date(),//
            nurseVerified: false,//
            emailVerified: false,//,
            nurseLoginControl: false,
            nurseAppAccessControl: false,
          })
          setAddRecord(false)
          getLocationJobList(orgLocationIdValue, orgLocation);
        }).catch(e => {
          // console.log(e)
          alert(e);
        })
      } catch (error) {
        console.log("data save error:", error);
      }
      // } else {
      //   alert(
      //     jobNewData.shiftTitle + " record already stored in database!"
      //   );
      // }
    } else {
      alert(
        validationResult.emptyFields +
        (validationResult.emptyFields.length > 1
          ? validationResult.emptyFields.length ===
            validationResult.emptyFields.length - 1
            ? ","
            : ""
          : "") +
        ". Please enter a value."
      );
    }
  };

  const clickEdit = (item) => {

    setJobNewData({
      ...jobNewData,
      email: item?.emailId,
      firstName: item?.firstName,
      lastName: item?.lastName,
      password: item?.password,
      phoneNumber: item?.phoneNumber,
      gender: item?.gender,
      dateOfBirth: new Date(item?.dateOfBirth),
      primaryState: item?.primaryState,
      fullAddress: item?.fullAddress,
      latitude: item?.latitude,
      longitude: item?.longitude,
      organization: item?.organization,
      location_id: item?.location_id,
      primaryLicenseType: item?.primaryLicenseType,
      primaryLicenseMultiPrivilege: item?.primaryLicenseMultiPrivilege,
      clinicalLicenseNumber: item?.clinicalLicenseNumber,
      clinicalLicenseExpirationDate: new Date(item?.clinicalLicenseExpirationDate),
      nurseVerified: item?.nurseVerified,
      emailVerified: item?.emailVerified,
      nurseLoginControl: item?.nurseLoginControl,
      nurseAppAccessControl: item?.nurseAppAccessControl,
    })
    setAddRecord(true)
    setEdit(item)
  }

  const updateCustomer = async (item) => {
    const validationResult = validateFields(jobNewData);
    if (validationResult.isValid) {
      // if (dataCheckEdit) { 
      try {
        await updateDetails(
          authToken, edit?.id,
          {
            email: jobNewData?.email,
            firstName: jobNewData?.firstName,
            lastName: jobNewData?.lastName,
            password: jobNewData?.password,
            phoneNumber: jobNewData?.phoneNumber,
            gender: jobNewData?.gender,
            dateOfBirth: jobNewData?.dateOfBirth,
            primaryState: jobNewData?.primaryState,
            fullAddress: jobNewData?.fullAddress,
            latitude: jobNewData?.latitude.toString(),
            longitude: jobNewData?.longitude.toString(),
            primaryLicenseType: jobNewData?.primaryLicenseType,
            primaryLicenseMultiPrivilege: jobNewData?.primaryLicenseMultiPrivilege,
            clinicalLicenseNumber: jobNewData?.clinicalLicenseNumber,
            clinicalLicenseExpirationDate: jobNewData?.clinicalLicenseExpirationDate,
            nurseLoginControl: jobNewData.nurseLoginControl,
            nurseAppAccessControl: jobNewData.nurseAppAccessControl,
          }
        ).then(res => {
          if (edit?.nurseLoginControl !== jobNewData.nurseLoginControl &&
            edit?.nurseAppAccessControl === jobNewData.nurseAppAccessControl) {
            // alert(jobNewData.nurseLoginControl ? "Activated" : "DeActivated");
            getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
              .then(async (facilitiesData) => {
                for (const facility of facilitiesData) {
                  try {
                    await postFacilityNotification(
                      authToken,
                      facility?.id,
                      "",
                      `${res?.firstName + " " + res?.lastName} has been ${jobNewData.nurseLoginControl ? "Activated" : "DeActivated"}`,
                      `${res?.firstName + " " + res?.lastName} has been ${jobNewData.nurseLoginControl ? "Activated" : "DeActivated"} by Admin.`,
                      "ManageUser",
                      {
                        id: ""
                      },
                      false,
                      false,
                      "green",
                      ""
                    );
                    socket?.emit("tableNotificationInsert",
                      {
                        table: "FacilityNotificationTable",
                        id: facility?.id,
                      });
                    socket?.emit("activateDeactivate",
                      {
                        table: "NurseTable",
                        id: res?.id,
                      });
                  } catch (error) {
                    console.error("Error fetching facility detail:", error);
                  }
                }
              })
              .catch((error) => console.error("Fetching facility:", error));
          } else if (edit?.nurseLoginControl === jobNewData.nurseLoginControl &&
            edit?.nurseAppAccessControl !== jobNewData.nurseAppAccessControl) {
            // alert(jobNewData.nurseAppAccessControl ? "Suspended" : "Un-Suspended");
            getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
              .then(async (facilitiesData) => {
                for (const facility of facilitiesData) {
                  try {
                    await postFacilityNotification(
                      authToken,
                      facility?.id,
                      "",
                      `${res?.firstName + " " + res?.lastName} has been ${jobNewData.nurseAppAccessControl ? "Suspended" : "Un-Suspended"}`,
                      `${res?.firstName + " " + res?.lastName} has been ${jobNewData.nurseAppAccessControl ? "Suspended" : "Un-Suspended"} by Admin.`,
                      "ManageUser",
                      {
                        id: ""
                      },
                      false,
                      false,
                      "green",
                      ""
                    );
                    socket?.emit("tableNotificationInsert",
                      {
                        table: "FacilityNotificationTable",
                        id: facility?.id,
                      });
                    socket?.emit("activateDeactivate",
                      {
                        table: "NurseTable",
                        id: res?.id,
                      });
                  } catch (error) {
                    console.error("Error fetching facility detail:", error);
                  }
                }
              })
              .catch((error) => console.error("Fetching facility:", error));
          } else if (edit?.nurseLoginControl !== jobNewData.nurseLoginControl &&
            edit?.nurseAppAccessControl !== jobNewData.nurseAppAccessControl) {
            if (jobNewData.nurseLoginControl && jobNewData.nurseAppAccessControl) {
              // alert("Activated after Suspended");
              getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
                .then(async (facilitiesData) => {
                  for (const facility of facilitiesData) {
                    try {
                      await postFacilityNotification(
                        authToken,
                        facility?.id,
                        "",
                        `${res?.firstName + " " + res?.lastName} has been Activated after Suspended`,
                        `${res?.firstName + " " + res?.lastName} has been Activated after Suspended by Admin.`,
                        "ManageUser",
                        {
                          id: ""
                        },
                        false,
                        false,
                        "green",
                        ""
                      );
                      socket?.emit("tableNotificationInsert",
                        {
                          table: "FacilityNotificationTable",
                          id: facility?.id,
                        });
                      socket?.emit("activateDeactivate",
                        {
                          table: "NurseTable",
                          id: res?.id,
                        });
                    } catch (error) {
                      console.error("Error fetching facility detail:", error);
                    }
                  }
                })
                .catch((error) => console.error("Fetching facility:", error));
            } else if (jobNewData.nurseLoginControl && !jobNewData.nurseAppAccessControl) {
              // alert("Activated after Un-Suspended");
              getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
                .then(async (facilitiesData) => {
                  for (const facility of facilitiesData) {
                    try {
                      await postFacilityNotification(
                        authToken,
                        facility?.id,
                        "",
                        `${res?.firstName + " " + res?.lastName} has been Activated after Un-Suspended`,
                        `${res?.firstName + " " + res?.lastName} has been Activated after Un-Suspended by Admin.`,
                        "ManageUser",
                        {
                          id: ""
                        },
                        false,
                        false,
                        "green",
                        ""
                      );
                      socket?.emit("tableNotificationInsert",
                        {
                          table: "FacilityNotificationTable",
                          id: facility?.id,
                        });
                      socket?.emit("activateDeactivate",
                        {
                          table: "NurseTable",
                          id: res?.id,
                        });
                    } catch (error) {
                      console.error("Error fetching facility detail:", error);
                    }
                  }
                })
                .catch((error) => console.error("Fetching facility:", error));
            } else if (!jobNewData.nurseLoginControl && jobNewData.nurseAppAccessControl) {
              // alert("Suspended after DeActivated");
              getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
                .then(async (facilitiesData) => {
                  for (const facility of facilitiesData) {
                    try {
                      await postFacilityNotification(
                        authToken,
                        facility?.id,
                        "",
                        `${res?.firstName + " " + res?.lastName} has been Suspended after DeActivated`,
                        `${res?.firstName + " " + res?.lastName} has been Suspended after DeActivated by Admin.`,
                        "ManageUser",
                        {
                          id: ""
                        },
                        false,
                        false,
                        "green",
                        ""
                      );
                      socket?.emit("tableNotificationInsert",
                        {
                          table: "FacilityNotificationTable",
                          id: facility?.id,
                        });
                      socket?.emit("activateDeactivate",
                        {
                          table: "NurseTable",
                          id: res?.id,
                        });
                    } catch (error) {
                      console.error("Error fetching facility detail:", error);
                    }
                  }
                })
                .catch((error) => console.error("Fetching facility:", error));
            } else {
              // alert("Un-Suspended after DeActivated");
              getAllFacilityOrgList(authToken, orgLocationIdValue, orgLocation)
                .then(async (facilitiesData) => {
                  for (const facility of facilitiesData) {
                    try {
                      await postFacilityNotification(
                        authToken,
                        facility?.id,
                        "",
                        `${res?.firstName + " " + res?.lastName} has been Un-Suspended after DeActivated`,
                        `${res?.firstName + " " + res?.lastName} has been Un-Suspended after DeActivated by Admin.`,
                        "ManageUser",
                        {
                          id: ""
                        },
                        false,
                        false,
                        "green",
                        ""
                      );
                      socket?.emit("tableNotificationInsert",
                        {
                          table: "FacilityNotificationTable",
                          id: facility?.id,
                        });
                      socket?.emit("activateDeactivate",
                        {
                          table: "NurseTable",
                          id: res?.id,
                        });
                    } catch (error) {
                      console.error("Error fetching facility detail:", error);
                    }
                  }
                })
                .catch((error) => console.error("Fetching facility:", error));
            }
          }
          alert(
            res?.firstName + " " + res?.lastName +
            " record updated successfully."
          );
          setAddRecord(false);
          setJobNewData({
            email: "",//
            firstName: "",//
            lastName: "",//
            password: "",//
            phoneNumber: "",//
            gender: "",//
            dateOfBirth: date18YearsAgo,//
            primaryState: "",//
            fullAddress: "",
            latitude: "",
            longitude: "",
            organization: "",//
            location_id: "",//
            primaryLicenseType: "",//
            primaryLicenseMultiPrivilege: false,//
            clinicalLicenseNumber: "",//
            clinicalLicenseExpirationDate: new Date(),//
            nurseVerified: false,//
            emailVerified: false,//,
            nurseLoginControl: false,
            nurseAppAccessControl: false,
          })
          getLocationJobList(orgLocationIdValue, orgLocation);
        }).catch(e => console.log(e))
      } catch (error) {
        console.log("data save error:", error);
      }
      // } else {
      //   alert(edit.patient_first_name + " record already stored in database!");
      // }
    } else {
      alert(
        validationResult.emptyFields +
        (validationResult.emptyFields.length > 1
          ? validationResult.emptyFields.length ===
            validationResult.emptyFields.length - 1
            ? ","
            : ""
          : "") +
        ". Please enter a value."
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(editedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(editedData);

  const handleSearch = (value) => {
    setSearchTerm(value);

    const filteredData = editedData.filter((item) => {
      for (const key in item) {
        const value = item[key];
        if (value && value.toString().toLowerCase().includes(searchTerm)) {
          return true;
        }
      }
      return false;
    });

    setSearchResults(filteredData);
  };

  const currentItems =
    searchTerm === ""
      ? editedData.slice(indexOfFirstItem, indexOfLastItem)
      : searchResults.slice(indexOfFirstItem, indexOfLastItem);

  function convertToCSV(jsonData) {
    const separator = ",";
    const keys = Object.keys(jsonData[0]);
    const csvContent = [
      keys.join(separator),
      ...jsonData.map((item) => {
        // Extract the 'FullAddress' and 'LicenseType' properties
        const { fullAddress, ...rest } = item;

        // Join the values of the other properties with the 'FullAddress' and 'LicenseType' values
        const values = keys.map((key) =>
          key === "fullAddress"
            ? `"${fullAddress}"`
            : rest[key]
        );

        return values.join(separator);
      }),
    ].join("\n");

    return csvContent;
  }

  const downloadCSVFile = (data) => {
    let current = moment().format("MM-DD-YYYY");
    // Create a Blob object for the CSV data
    const blob = new Blob([data], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `${orgLocationIdValue}-EmployeeList-${current}.csv`;

    // Trigger the download
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  const deleteRecord = async (item) => {
    // console.log(item);
    setSearchTerm("");
    setOpen({ condition: false, item: {} });
    getLocationJobList(orgLocationIdValue, orgLocation);
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
  };

  const closeSelect = () => {
    setDeleteShow(false);
    setSelectedItems([]);
  };

  const deleteMultipleRecord = async () => {
    for (let i = 0; i < selectedItems.length; i++) {
      // console.log(selectedItems[i]);
    }
    getLocationJobList(orgLocationIdValue, orgLocation);
    setSelectedItems([]);
    setDeleteShow(false);
    setDeleteAll(false);
  };

  // console.log(selectedItems);

  return (
    <div>
      {loadingScreen ? (
        <div className="flex justify-center items-center mt-10">
          Loading....
        </div>
      ) : (
        <div className="mt-0">

          <div className="flex justify-end mt-1 mr-5 items-center space-x-2">
            <button
              className="bg-green-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                history.push("/upload-nurse");
              }}
            >
              Upload CSV
            </button>
            <button
              className="bg-blue-600 my-3 text-white px-5 py-1 rounded-md"
              onClick={() => {
                orgLocationIdValue === ""
                  ? alert("Select location id")
                  : setAddRecord(true);
              }}
            >
              Add User
            </button>
          </div>

          <div className="flex justify-between mx-5 items-center">
            {/* <div>
             <div className="flex flex-row items-center justify-center">
                <button
                  className="bg-blue-600 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center "
                  onClick={() => {
                    setOrgLocationIdView(!orgLocationIdView);
                  }}
                >
                  Select Location Id
                  <p className="font-medium">
                    {orgLocationIdValue !== "" && " - " + orgLocationIdValue}
                  </p>
                  {orgLocationIdView ? (
                    <IoMdArrowDropup />
                  ) : (
                    <IoMdArrowDropdown />
                  )}
                </button>

                {orgLocationIdValue !== "" && (
                  <MdOutlineClose
                    onClick={() => {
                      setOrgLocationIdValue("");
                      closeSelect();
                    }}
                    className="ml-2 text-xl cursor-pointer"
                  />
                )}
                {orgLocationIdValue !== "" && selectedItems.length !== 0 && (
                  <button
                    className={`${deleteShow ? "bg-red-600" : "bg-blue-600"
                      } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      setDeleteAll(true);
                    }}
                  >
                    Delete All
                  </button>
                )}
                {orgLocationIdValue !== "" && (
                  <button
                    className={`${deleteShow ? "bg-red-600" : "bg-blue-600"
                      } ml-2 my-3 text-white px-4 py-1 flex-row  rounded-md
                   focus:outline-none text-center inline-flex items-center`}
                    onClick={() => {
                      deleteShow ? closeSelect() : setDeleteShow(true);
                    }}
                  >
                    {deleteShow ? "Cancel" : "Select Multiple"}
                  </button>
                )}
              </div>

              {orgLocationIdView && (
                <div
                  className={`z-10 absolute bg-white  
               divide-gray-100 rounded-lg 
               shadow w-44`}
                >
                  <ul className="py-2 text-sm text-gray-700 cursor-pointer">
                    {orgLocationId?.map((item, index) => {
                      return (
                        <li key={index}>
                          <p
                            onClick={() => {
                              setOrgLocationIdValue(item);
                              setOrgLocationIdView(false);
                            }}
                            className="block px-4 py-2 
                        hover:bg-gray-100"
                          >
                            {item}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div> */}

            <div className="flex items-center">
              <List
                data={orgLocationIdValueList}
                handleInputChangeNewData={(item) => {
                  setOrgLocationIdValue(item);
                  setOrgLocationIdValueList({
                    name: "Location Id - " + item
                  });
                }}
                options={orgLocationId} />
              {orgLocationIdValue !== "" && (
                <MdOutlineClose
                  onClick={() => {
                    setOrgLocationIdValue("");
                    setManagerList(undefined);
                    setOrgLocationIdValueList({
                      "name": "Select location Id"
                    });
                    closeSelect();
                  }}
                  className="ml-2 text-xl cursor-pointer"
                />
              )}
            </div>

            <input
              type="text"
              placeholder="Search Record..."
              value={searchTerm}
              onChange={(event) =>
                handleSearch(event.target.value.toLowerCase())
              }
              className="border border-gray-200 p-3 h-10 
              rounded mb-3 focus:border-gray-300 outline-gray-300"
            />
          </div>
          {orgLocationIdValue === "" ? (
            <div className="flex justify-center items-center mt-10">
              Select Location Id
            </div>
          ) : loading ? (
            <div className="flex justify-center items-center mt-10">
              Loading....
            </div>
          ) : currentItems.length === 0 ? (
            <div className="flex justify-center items-center mt-10">
              {orgLocationIdValue} - Empty list
            </div>
          ) : (
            <div>
              <div className="mx-5 overflow-x-scroll">

                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th style={{ border: "1px solid black", padding: "8px" }}>
                        <p>ACTION</p>
                      </th>
                      {jobColumnsData.map((column) => (
                        <th
                          key={column.accessor}
                          style={{ border: "1px solid black", padding: "8px" }}
                          className="uppercase"
                        >
                          {column.Header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>

                    {currentItems.map((item, index) => (
                      <tr class="bg-white border-b
                        hover:bg-gray-50"
                        key={index}>
                        <td
                          style={{ border: "1px solid black", padding: "8px" }}
                        >
                          <dix className={`flex flex-row ${"justify-between"}`}>
                            <div
                              className="flex flex-row justify-center
                                 items-center w-full"
                            >
                              <div
                                className="text-primary
                                      transition duration-150 ease-in-out 
                                      hover:text-primary-600 focus:text-primary-600 
                                      active:text-primary-700 cursor-pointer"
                                data-te-toggle="tooltip"
                                title="Edit"
                              >
                                <MdEdit
                                  onClick={() => {
                                    clickEdit(item)
                                  }}
                                  className="text-blue-600"
                                />
                              </div>

                              {/* {deleteShow ? (
                                <div
                                  className="transititext-primary text-primary
                                 transition duration-150 ease-in-out 
                                 hover:text-primary-600 focus:text-primary-600 
                                 active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Select"
                                >
                                  <div class="flex items-center">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      class="w-4 h-4 text-blue-600 bg-gray-100
                                        border-gray-300 rounded"
                                      checked={selectedItems.some(
                                        (value) => value?.id === item.id
                                      )}
                                      onChange={(event) =>
                                        handleCheckboxChange(event, item)
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="transititext-primary text-primary
                                  transition duration-150 ease-in-out 
                                  hover:text-primary-600 focus:text-primary-600 
                                  active:text-primary-700 cursor-pointer"
                                  data-te-toggle="tooltip"
                                  title="Delete"
                                >
                                  <MdDelete
                                    className="text-red-600"
                                    onClick={() =>
                                      setOpen({
                                        condition: true,
                                        item: item,
                                      })
                                    }
                                  />
                                </div>
                              )} */}
                            </div>
                          </dix>
                        </td>
                        {jobColumnsData.map((column) => (
                          <td
                            key={column.accessor}
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            {
                              column.accessor === "dateOfBirth"
                                ? <p className="">{moment(item[column.accessor]).format("MM-DD-YYYY")}</p>
                                : column.accessor === "clinicalLicenseExpirationDate"
                                  ? <p className="w-24">{moment(item[column.accessor]).format("MM-DD-YYYY")}</p>
                                  : column.accessor === "primaryLicenseMultiPrivilege"
                                    ? <p className="">{item[column.accessor] ? "Yes" : "No"}</p>
                                    : column.accessor === "nurseVerified"
                                      ? <p className="">{item[column.accessor] ? "Yes" : "No"}</p>
                                      : column.accessor === "emailVerified"
                                        ? <p className="w-24">{item[column.accessor] ? "Yes" : "No"}</p>
                                        : column.accessor === "nurseLoginControl"
                                          ? <p className="w-24">{item[column.accessor] ? "Yes" : "No"}</p>
                                          : column.accessor === "nurseAppAccessControl"
                                            ? <p className="w-24">{item[column.accessor] ? "Yes" : "No"}</p>
                                            : column.accessor === "phoneNumber"
                                              ? <p className="w-24">{format(item[column.accessor])}</p>
                                              :
                                              <p style={{ padding: "9px" }}>
                                                {item[column.accessor]}
                                              </p>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="space-x-3 mr-5 flex justify-end my-5 items-center">
                <button
                  className="bg-green-600 my-5 text-white px-5 py-1 rounded-md"
                  onClick={() => downloadCSVFile(convertToCSV(downloadData))}
                >
                  Download
                </button>
                <div>
                  <label>Items per page:</label>
                  <select
                    value={itemsPerPage}
                    onChange={(event) => setItemsPerPage(event.target.value)}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <button
                  onClick={() => handlePrevPage()}
                  className={`px-5 py-1 rounded-md ${currentPage > 1
                    ? "bg-blue-500 text-white"
                    : "bg-slate-100 text-black cursor-not-allowed"
                    }`}
                >
                  pre
                </button>
                <p>{currentPage}</p>
                <button
                  onClick={() => handleNextPage()}
                  className={`px-5 py-1 rounded-md ${currentPage < pageNumbers.length
                    ? "bg-blue-500 text-white"
                    : "bg-slate-100 text-black cursor-not-allowed"
                    }`}
                >
                  next
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <Transition.Root show={open.condition} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setOpen({ condition: e, item: {} })}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete ?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteRecord(open.item)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen({ condition: false, item: {} })}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={deleteAll} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setDeleteAll(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdDelete className="text-red-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete All Record
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete all record?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => deleteMultipleRecord()}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteAll(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addRecord} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setAddRecord(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full 
            items-end justify-center p-4 
            text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all mx-16 my-5 w-full">
                  <div className="px-4 flex-1 bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-5">
                        <NurseForm
                          jobNewData={jobNewData}
                          handleInputChangeNewData={handleInputChangeNewData}
                          edit={edit}
                          orgLocationIdValue={orgLocationIdValue}
                          address={address}
                          setAddress={setAddress}
                        />
                      </div>
                    </div>
                  </div>
                  {!address &&
                    <div className="bg-white px-4 pb-6 sm:flex
                   sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md
                       bg-green-600 px-3 py-2 text-sm font-semibold text-white
                        shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => edit !== undefined
                          ? updateCustomer(edit)
                          : postJob()}
                      >
                        {
                          edit !== undefined
                            ? "Update User"
                            : "Create User"
                        }
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center
                       rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setAddRecord(false)
                          setEdit(undefined)
                          setJobNewData({
                            email: "",//
                            firstName: "",//
                            lastName: "",//
                            password: "",//
                            phoneNumber: "",//
                            gender: "",//
                            dateOfBirth: date18YearsAgo,//
                            primaryState: "",//
                            fullAddress: "",
                            latitude: "",
                            longitude: "",
                            organization: "",//
                            location_id: "",//
                            primaryLicenseType: "",//
                            primaryLicenseMultiPrivilege: false,//
                            clinicalLicenseNumber: "",//
                            clinicalLicenseExpirationDate: new Date(),//
                            nurseVerified: false,//
                            emailVerified: false,//,
                            nurseLoginControl: false,
                            nurseAppAccessControl: false,
                          })
                        }}
                      >
                        Cancel
                      </button>
                    </div>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default NurseTableView;
