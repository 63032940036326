import React from "react";
import dp from "../../assets/icon.jpg";

const NurseData = ({ nurse }) => {
  // console.log(nurse)
  return (
    <div>
      {nurse === undefined ? (
        <h1>Loading...</h1>
      ) : (
        <div
          className={`flex mt-2 rounded p-1 pl-2 pr-3
          ${
            !nurse?.nurseLoginControl ||
            nurse?.nurseAppAccessControl ||
            !nurse?.availableDate ||
            !nurse?.availableTime
              ? "bg-red-200"
              : "bg-green-200"
          }`}
        >
          <div className="flex flex-1 items-center">
            <div className="h-8 w-8 rounded-full">
              {nurse?.profileImage ? (
                <img
                  className="h-8 w-8 rounded-full"
                  src={nurse?.profileImage}
                  alt=""
                />
              ) : (
                <img className="h-8 w-8 rounded-full" src={dp} alt="" />
              )}
            </div>
            <div className="flex-1 ml-2">
              <div className="flex space-x-1">
                <p className="text-xs text-blue-500 font-medium flex">
                  {nurse?.firstName}
                </p>
                <p className="text-xs text-blue-500 font-medium flex">
                  {nurse?.lastName}
                </p>
              </div>
              <p className="text-[10px] font-medium flex">
                {nurse?.primaryLicenseType}
              </p>
              {/* <h1 className='text-xs font-medium flex'>
                  {nurse?.nurseAppAccessControl ? "Suspend" : "Un-Suspend"}
                </h1>
                <h1 className='text-xs font-medium flex'>
                  {nurse?.nurseLoginControl ? "Activate" : "DeActivate"}
                </h1> */}
            </div>
          </div>
          {(!nurse?.nurseLoginControl ||
            nurse?.nurseAppAccessControl ||
            !nurse?.availableDate ||
            !nurse?.availableTime) && (
            <span class="relative flex h-3 w-3 mt-1">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default NurseData;
