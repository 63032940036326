import React from 'react'
import List from './List'
import { MdOutlineAdd } from 'react-icons/md'
import AutoFillAddress from './AutoFillAddress'

function CustomerForm({
  jobNewData,
  handleInputChangeNewData,
  edit,
  orgLocationIdValue,
  address,
  setAddress
}) {

  const gender = [
    { name: "Male" },
    { name: "Female" },
    { name: "Others" }
  ]

  const format = (value) => {
    const inputPhoneNumber = value.replace(/\D/g, '');
    const limitedPhoneNumber = inputPhoneNumber.slice(0, 10);
    const formattedPhoneNumber = limitedPhoneNumber.length === 10 ? limitedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : limitedPhoneNumber;
    return formattedPhoneNumber
  }

  const handlePhoneNumberChange1 = (e) => {
    const inputPhoneNumber = e.target.value
    handleInputChangeNewData({
      target: {
        name: "patientOne",
        value: inputPhoneNumber
      },
    })
  };

  const handlePhoneNumberChange2 = (e) => {
    const inputPhoneNumber = e.target.value
    handleInputChangeNewData({
      target: {
        name: "patientTwo",
        value: inputPhoneNumber
      },
    })
  };

  return (
    <div className='pb-20'>
      <div className="grid gap-6 grid-cols-2 border-b-2 mb-5
       items-center center">
        <div className='flex justify-between'>
          <div className="flex items-center">
            <div className="flex h-12 w-12 flex-shrink-0
             items-center justify-center rounded-full bg-green-100 ">
              <MdOutlineAdd className="text-green-600 " size={24} />
            </div>
            <p className="text-xl font-medium leading-6 text-gray-900 ml-3"
            >
              {edit ? "Update Patient" : "Create New Patient"}
            </p>
          </div>
        </div>
        <div className='items-end flex justify-end font-medium text-blue-500'>
          {orgLocationIdValue}
        </div>
      </div>
      {!address ? <div className="grid gap-6 grid-cols-3">
        <div>
          <div className='flex'>
            <label for="firstName"
              className="block mb-2 text-sm font-medium text-gray-900">First Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="firstName"
            name='firstName'
            value={jobNewData?.firstName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 "
            placeholder="Enter First Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="lastName"
              className="block mb-2 text-sm font-medium text-gray-900">Last Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="lastName"
            name='lastName'
            value={jobNewData?.lastName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Last Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="email"
              className="block mb-2 text-sm font-medium text-gray-900">Email
            </label>
          </div>
          <input
            type="text"
            id="email"
            name='email'
            value={jobNewData?.email}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Email"
            onChange={e => handleInputChangeNewData(e)}
          />
          {jobNewData?.email && !jobNewData?.email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
          )}
        </div>
        <div>
          <div className='flex'>
            <label
              for="customerVisibility"
              className="block mb-2 text-sm font-medium text-gray-900">
              Gender </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <List
              data={jobNewData?.gender}
              handleInputChangeNewData={handleInputChangeNewData}
              options={gender}
              name="gender" />
          </div>
        </div>
        <div>
          <div className='flex'>
            <label for="patientOne"
              className="block mb-2 text-sm font-medium text-gray-900">Primary Phone Number
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="patientOne"
            name='patientOne'
            value={format(jobNewData?.patientOne)}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Phone Number"
            onChange={e => handlePhoneNumberChange1(e)}
            pattern="[0-9]*"
          />
        </div>
        <div>
          <div className='flex'>
            <label for="patientTwo"
              className="block mb-2 text-sm font-medium text-gray-900">Secondary Phone Number
            </label>
          </div>
          <input
            type="text"
            id="patientTwo"
            name='patientTwo'
            value={format(jobNewData?.patientTwo)}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Phone Number"
            onChange={e => handlePhoneNumberChange2(e)}
            pattern="[0-9]*"
          />
        </div>
        <div>
          <div className='flex'>
            <label
              for="fullAddress"
              className="block mb-2 text-sm font-medium text-gray-900">
              Full Address</label>
            <p className='text-red-500'>*</p>
          </div>
          <div
            type="text"
            name='fullAddress'
            id="fullAddress"
            value={jobNewData?.fullAddress}
            className="bg-gray-50 
          border border-gray-300 text-gray-900 text-sm rounded-lg
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            onClick={() => setAddress(true)} >
            {jobNewData?.fullAddress ? jobNewData?.fullAddress : "Full Address"}
          </div>
        </div>
      </div> :
        <AutoFillAddress
          setShowAddress={setAddress}
          handleInputChangeNewData={handleInputChangeNewData}
        />}
      {/* <div className="flex items-start mb-6">
        <div className="flex items-center h-5">
          <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300   dark:focus:ring-blue-600 dark:ring-offset-gray-800"   />
        </div>
        <label for="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
      </div> */}
      {/* <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button> */}
    </div >
  )
}

export default CustomerForm