import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
  // useHistory
} from "react-router-dom";
// import Home from "./screens/Home";
import { Menu, Transition } from "@headlessui/react";
import About from "./screens/About";
// import icon from "./assets/icon.png";
import logo from "./assets/logo.png";
import CSVUpload from "./screens/customer/CSVUpload";
import CSVUploadJob from "./screens/job/CSVUploadJob";
import CSVUploadNurse from "./screens/user/nurse/CSVUpload";
import CSVUploadManager from "./screens/user/manager/CSVUpload";
import LoginForm from "./screens/Login";
import { Fragment, useEffect, useState } from "react";
import Dashboard from "./screens/Dashboard";
import CustomerTable from "./screens/customer/CustomerTable";
import JobTableView from "./screens/job/JobView";
import UserView from "./screens/user/UserView";
import LocationDetails from "./screens/location/LocationDetails";
import { useAuth, AuthContextProvider } from "./context/AuthContext";
import { getOrganizationDetails } from "./api_url/organizationTable";
import { socket } from "./api_url/socket";
import dp from "./assets/dp.jpg";
import { ChartView } from "./components/ChartView";
import Calendar from "./screens/schedule/Calendar";
import Open from "./OpenAi";

function App() {
  // const history = useHistory();
  const [user, setUser] = useState(undefined);
  const [screen, setScreen] = useState("home");

  const { authToken, userId, removeAuthToken } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState(true);

  const userNavigation = [{ name: "Profile", href: "/profile" }];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // console.log(authToken);

  useEffect(() => {
    setUser(undefined);
    const check = async () => {
      await getOrganizationDetails(authToken, userId)
        .then((res) => {
          setUser(res);
          socket?.emit("setup", res);
          socket?.on("connected", () => console.log("connected", ""));
          setLoadingScreen(false);
        })
        .catch((e) => {
          if (e) {
            setUser(null);
            setLoadingScreen(false);
            removeAuthToken();
          }
        });
    };
    check();
  }, [authToken, removeAuthToken, userId]);

  const logout = () => {
    removeAuthToken();
  };

  return (
    <div>
      {loadingScreen ? (
        <div className="flex justify-center items-center mt-10">
          Loading....
        </div>
      ) : user ? (
        <Router>
          <header className="flex justify-between px-5 py-5 shadow-md">
            <NavLink
              to="/"
              style={(isActive) => ({
                display: "flex",
                alignItems: "center"
              })}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <img src={icon} alt="icon" width={40} height={40} /> */}
                <img src={logo} alt="logo" width={100} height={25} />
              </div>
            </NavLink>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <NavLink
                to="/"
                style={(isActive) => ({
                  color: isActive ? "blue" : "black",
                  fontWeight: isActive ? "bold" : "500",
                  paddingLeft: 20,
                })}
              >
                <p>Home</p>
              </NavLink> */}
              <NavLink
                to="/about"
                style={(isActive) => ({
                  color: isActive ? "blue" : "black",
                  fontWeight: isActive ? "bold" : "500",
                  paddingLeft: 20
                })}
              >
                <p>About</p>
              </NavLink>
              <NavLink
                to="/user"
                style={(isActive) => ({
                  color: isActive ? "blue" : "black",
                  fontWeight: isActive ? "bold" : "500",
                  paddingLeft: 20
                })}
              >
                <p>User</p>
              </NavLink>
              <NavLink
                to="/patients"
                style={(isActive) => ({
                  color: isActive ? "blue" : "black",
                  fontWeight: isActive ? "bold" : "500",
                  paddingLeft: 20
                })}
              >
                <p>Patients</p>
              </NavLink>
              <NavLink
                to="/jobs"
                style={(isActive) => ({
                  color: isActive ? "blue" : "black",
                  fontWeight: isActive ? "bold" : "500",
                  paddingLeft: 20
                })}
              >
                <p>Jobs</p>
              </NavLink>
              {/* <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  paddingLeft: 20,
                }}
                className="cursor-pointer"
                onClick={() => {
                  logout();
                }}
              >
                LogOut
              </p> */}
              <div className="">
                {/* Profile dropdown */}
                <Menu as="div" className="relative mx-3">
                  <div>
                    <Menu.Button
                      className="flex max-w-xs items-center
                       rounded-full bg-gray-800 text-sm focus:outline-none 
                       focus:ring-2 focus:ring-white focus:ring-offset-2
                        focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8 w-8 rounded-full" src={dp} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <p
                          onClick={() => logout()}
                          className={classNames(
                            "block px-4 py-2 text-sm cursor-pointer text-gray-700"
                          )}
                        >
                          Log Out
                        </p>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </header>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/about" component={About} />
            <Route path="/patients" component={CustomerTable} />
            <Route path="/jobs" component={JobTableView} />
            <Route path="/user" component={UserView} />
            <Route path="/upload-jobs" component={CSVUploadJob} />
            <Route path="/upload-patient" component={CSVUpload} />
            <Route path="/upload-nurse" component={CSVUploadNurse} />
            <Route path="/upload-manager" component={CSVUploadManager} />
            <Route path="/profile" component={LocationDetails} />
            <Route path="/report" component={ChartView} />
            <Route path="/schedule" component={Calendar} />
            <Route path="/ai-schedule" component={Open} />
          </Switch>
        </Router>
      ) : (
        <Router>
          {screen === "home" ? (
            <div>
              <header className="flex justify-between px-5 py-5 shadow-md">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={icon} alt="icon" width={40} height={40} /> */}
                  <img src={logo} alt="logo" width={100} height={25} />
                </div>
                <div className="flex space-x-5">
                  <p
                    className="text-black font-medium cursor-pointer"
                    style={{
                      color: "blue",
                      fontWeight: "bold"
                    }}
                  >
                    Home
                  </p>
                  <p
                    className="text-black font-medium cursor-pointer"
                    onClick={() => setScreen("about")}
                  >
                    About
                  </p>
                  <p
                    className="text-black font-medium cursor-pointer"
                    onClick={() => {
                      //   try {
                      //     let response = await fetch("http://192.168.29.215:3000/");
                      //     console.log(response)
                      //   } catch (error) {
                      //     console.log(error, "error")
                      //   }
                      //
                      window.open(
                        "https://nursd-flow-dev-api-web.vercel.app",
                        "_blank"
                      );
                    }}
                  >
                    Manager
                  </p>
                  <p
                    className="text-black font-medium cursor-pointer"
                    onClick={() => setScreen("login")}
                  >
                    Admin
                  </p>
                </div>
              </header>

              <Dashboard />
            </div>
          ) : screen === "about" ? (
            <div>
              <header className="flex justify-between px-5 py-5 shadow-md">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={icon} alt="icon" width={40} height={40} /> */}
                  <img src={logo} alt="logo" width={100} height={25} />
                </div>
                <div className="flex space-x-5">
                  <p
                    className="text-black font-medium cursor-pointer"
                    onClick={() => setScreen("home")}
                  >
                    Home
                  </p>
                  <p
                    className="text-black font-medium cursor-pointer"
                    style={{
                      color: "blue",
                      fontWeight: "bold"
                    }}
                  >
                    About
                  </p>
                  <p
                    className="text-black font-medium cursor-pointer"
                    onClick={() => setScreen("login")}
                  >
                    Login
                  </p>
                </div>
              </header>

              <About />
            </div>
          ) : (
            <div>
              <header className="flex justify-between px-5 py-5 shadow-md">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img src={icon} alt="icon" width={40} height={40} /> */}
                  <img src={logo} alt="logo" width={100} height={25} />
                </div>
                <p
                  className="text-black font-medium cursor-pointer"
                  onClick={() => setScreen("home")}
                >
                  Back to home
                </p>
              </header>

              <LoginForm />
            </div>
          )}
        </Router>
      )}
    </div>
  );
}

// export default App;
export default function AppWithAuthProvider() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}

// import React from 'react'
// import PDF from "./PDF"
// import ScheduleComponent from './ScheduleComponent'
// import OpenAi from './OpenAi'

// export default function App() {
//   return (
//     <OpenAi />
//   )
// }
