import React from "react";

function Dashboard() {
  return (
    <div>
      <div className="background">
        <p
          style={{
            fontSize: 40,
            fontWeight: 500,
          }}
        >
          In-demand healthcare talent on demand
        </p>
        <p
          style={{
            fontSize: 20,
            fontWeight: 400,
          }}
        >
          Get empowered with NURSD Health
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
