import { API_URL } from "./config";

export async function getOrganizationDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getOrganizationUniqueIdDetails(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/uniqueId/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getOrganizationByEmail(authToken, email) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/by/email/${email}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAllFacilityOrgList(authToken, location, organization) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/all/by/location/list/data/${location}/${organization}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function getAllNurseOrgList(authToken, location, organization) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/all/by/location/list/data/nurse/${location}/${organization}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching user");
  }
  return await res.json();
}

export async function updateDetails(authToken, id, data) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/organization/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data), // Convert data to JSON string
  });

  if (res.status === 400) {
    throw new Error("Failed to update the user. Try again!");
  }

  return await res.json();
} 